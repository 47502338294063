import { r as react } from './index-ec48241a.js';
import { i as invariant } from './bundle.esm-0cbc1c1a.js';
import { a as __extends, b as __assign } from './tslib.es6-db19acae.js';
import { N as NetworkStatus, A as ApolloError } from './bundle.esm-239c7ba4.js';
import { e as equal } from './equality.esm-5339f780.js';

var apolloContext;

function getApolloContext() {
  if (!apolloContext) {
    apolloContext = react.createContext({});
  }

  return apolloContext;
}

var ApolloProvider = function (_a) {
  var client = _a.client,
      children = _a.children;
  var ApolloContext = getApolloContext();
  return react.createElement(ApolloContext.Consumer, null, function (context) {
    if (context === void 0) {
      context = {};
    }

    if (client && context.client !== client) {
      context = Object.assign({}, context, {
        client: client
      });
    }

    invariant(context.client, 5) ;
    return react.createElement(ApolloContext.Provider, {
      value: context
    }, children);
  });
};

var DocumentType;

(function (DocumentType) {
  DocumentType[DocumentType["Query"] = 0] = "Query";
  DocumentType[DocumentType["Mutation"] = 1] = "Mutation";
  DocumentType[DocumentType["Subscription"] = 2] = "Subscription";
})(DocumentType || (DocumentType = {}));

var cache = new Map();

function operationName(type) {
  var name;

  switch (type) {
    case DocumentType.Query:
      name = 'Query';
      break;

    case DocumentType.Mutation:
      name = 'Mutation';
      break;

    case DocumentType.Subscription:
      name = 'Subscription';
      break;
  }

  return name;
}

function parser(document) {
  var cached = cache.get(document);
  if (cached) return cached;
  var variables, type, name;
  invariant(!!document && !!document.kind, 1) ;
  var fragments = document.definitions.filter(function (x) {
    return x.kind === 'FragmentDefinition';
  });
  var queries = document.definitions.filter(function (x) {
    return x.kind === 'OperationDefinition' && x.operation === 'query';
  });
  var mutations = document.definitions.filter(function (x) {
    return x.kind === 'OperationDefinition' && x.operation === 'mutation';
  });
  var subscriptions = document.definitions.filter(function (x) {
    return x.kind === 'OperationDefinition' && x.operation === 'subscription';
  });
  invariant(!fragments.length || queries.length || mutations.length || subscriptions.length, 2) ;
  invariant(queries.length + mutations.length + subscriptions.length <= 1, 3) ;
  type = queries.length ? DocumentType.Query : DocumentType.Mutation;
  if (!queries.length && !mutations.length) type = DocumentType.Subscription;
  var definitions = queries.length ? queries : mutations.length ? mutations : subscriptions;
  invariant(definitions.length === 1, 4) ;
  var definition = definitions[0];
  variables = definition.variableDefinitions || [];

  if (definition.name && definition.name.kind === 'Name') {
    name = definition.name.value;
  } else {
    name = 'data';
  }

  var payload = {
    name: name,
    type: type,
    variables: variables
  };
  cache.set(document, payload);
  return payload;
}

var OperationData = function () {
  function OperationData(options, context) {
    this.isMounted = false;
    this.previousOptions = {};
    this.context = {};
    this.options = {};
    this.options = options || {};
    this.context = context || {};
  }

  OperationData.prototype.getOptions = function () {
    return this.options;
  };

  OperationData.prototype.setOptions = function (newOptions, storePrevious) {
    if (storePrevious === void 0) {
      storePrevious = false;
    }

    if (storePrevious && !equal(this.options, newOptions)) {
      this.previousOptions = this.options;
    }

    this.options = newOptions;
  };

  OperationData.prototype.unmount = function () {
    this.isMounted = false;
  };

  OperationData.prototype.refreshClient = function () {
    var client = this.options && this.options.client || this.context && this.context.client;
    invariant(!!client, 2) ;
    var isNew = false;

    if (client !== this.client) {
      isNew = true;
      this.client = client;
      this.cleanup();
    }

    return {
      client: this.client,
      isNew: isNew
    };
  };

  OperationData.prototype.verifyDocumentType = function (document, type) {
    var operation = parser(document);
    operationName(type);
    operationName(operation.type);
    invariant(operation.type === type, 3) ;
  };

  return OperationData;
}();

var QueryData = function (_super) {
  __extends(QueryData, _super);

  function QueryData(_a) {
    var options = _a.options,
        context = _a.context,
        onNewData = _a.onNewData;

    var _this = _super.call(this, options, context) || this;

    _this.previousData = {};
    _this.currentObservable = {};
    _this.runLazy = false;

    _this.runLazyQuery = function (options) {
      _this.cleanup();

      _this.runLazy = true;
      _this.lazyOptions = options;

      _this.onNewData();
    };

    _this.getExecuteResult = function () {
      var result = _this.getQueryResult();

      _this.startQuerySubscription();

      return result;
    };

    _this.obsRefetch = function (variables) {
      return _this.currentObservable.query.refetch(variables);
    };

    _this.obsFetchMore = function (fetchMoreOptions) {
      return _this.currentObservable.query.fetchMore(fetchMoreOptions);
    };

    _this.obsUpdateQuery = function (mapFn) {
      return _this.currentObservable.query.updateQuery(mapFn);
    };

    _this.obsStartPolling = function (pollInterval) {
      _this.currentObservable && _this.currentObservable.query && _this.currentObservable.query.startPolling(pollInterval);
    };

    _this.obsStopPolling = function () {
      _this.currentObservable && _this.currentObservable.query && _this.currentObservable.query.stopPolling();
    };

    _this.obsSubscribeToMore = function (options) {
      return _this.currentObservable.query.subscribeToMore(options);
    };

    _this.onNewData = onNewData;
    return _this;
  }

  QueryData.prototype.execute = function () {
    this.refreshClient();

    var _a = this.getOptions(),
        skip = _a.skip,
        query = _a.query;

    if (skip || query !== this.previousData.query) {
      this.removeQuerySubscription();
      this.previousData.query = query;
    }

    this.updateObservableQuery();
    if (this.isMounted) this.startQuerySubscription();
    return this.getExecuteSsrResult() || this.getExecuteResult();
  };

  QueryData.prototype.executeLazy = function () {
    return !this.runLazy ? [this.runLazyQuery, {
      loading: false,
      networkStatus: NetworkStatus.ready,
      called: false,
      data: undefined
    }] : [this.runLazyQuery, this.execute()];
  };

  QueryData.prototype.fetchData = function () {
    var options = this.getOptions();
    if (options.skip || options.ssr === false) return false;
    var obs = this.currentObservable.query;
    var currentResult = obs.getCurrentResult();
    return currentResult.loading ? obs.result() : false;
  };

  QueryData.prototype.afterExecute = function (_a) {
    var _b = (_a === void 0 ? {} : _a).lazy,
        lazy = _b === void 0 ? false : _b;
    this.isMounted = true;

    if (!lazy || this.runLazy) {
      this.handleErrorOrCompleted();
    }

    this.previousOptions = this.getOptions();
    return this.unmount.bind(this);
  };

  QueryData.prototype.cleanup = function () {
    this.removeQuerySubscription();
    delete this.currentObservable.query;
    delete this.previousData.result;
  };

  QueryData.prototype.getOptions = function () {
    var options = _super.prototype.getOptions.call(this);

    if (this.lazyOptions) {
      options.variables = __assign(__assign({}, options.variables), this.lazyOptions.variables);
      options.context = __assign(__assign({}, options.context), this.lazyOptions.context);
    }

    if (this.runLazy) {
      delete options.skip;
    }

    return options;
  };

  QueryData.prototype.ssrInitiated = function () {
    return this.context && this.context.renderPromises;
  };

  QueryData.prototype.getExecuteSsrResult = function () {
    var ssrDisabled = this.getOptions().ssr === false;
    var fetchDisabled = this.refreshClient().client.disableNetworkFetches;

    var ssrLoading = __assign({
      loading: true,
      networkStatus: NetworkStatus.loading,
      called: true,
      data: undefined,
      stale: false,
      client: this.client
    }, this.observableQueryFields());

    if (ssrDisabled && (this.ssrInitiated() || fetchDisabled)) {
      this.previousData.result = ssrLoading;
      return ssrLoading;
    }

    var result;

    if (this.ssrInitiated()) {
      result = this.context.renderPromises.addQueryPromise(this, this.getExecuteResult) || ssrLoading;
    }

    return result;
  };

  QueryData.prototype.prepareObservableQueryOptions = function () {
    var options = this.getOptions();
    this.verifyDocumentType(options.query, DocumentType.Query);
    var displayName = options.displayName || 'Query';

    if (this.ssrInitiated() && (options.fetchPolicy === 'network-only' || options.fetchPolicy === 'cache-and-network')) {
      options.fetchPolicy = 'cache-first';
    }

    return __assign(__assign({}, options), {
      displayName: displayName,
      context: options.context,
      metadata: {
        reactComponent: {
          displayName: displayName
        }
      }
    });
  };

  QueryData.prototype.initializeObservableQuery = function () {
    var _a, _b;

    if (this.ssrInitiated()) {
      this.currentObservable.query = this.context.renderPromises.getSSRObservable(this.getOptions());
    }

    if (!this.currentObservable.query) {
      var observableQueryOptions = this.prepareObservableQueryOptions();
      this.previousData.observableQueryOptions = __assign(__assign({}, observableQueryOptions), {
        children: null
      });
      this.currentObservable.query = this.refreshClient().client.watchQuery(__assign({}, observableQueryOptions));

      if (this.ssrInitiated()) {
        (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.renderPromises) === null || _b === void 0 ? void 0 : _b.registerSSRObservable(this.currentObservable.query, observableQueryOptions);
      }
    }
  };

  QueryData.prototype.updateObservableQuery = function () {
    if (!this.currentObservable.query) {
      this.initializeObservableQuery();
      return;
    }

    var newObservableQueryOptions = __assign(__assign({}, this.prepareObservableQueryOptions()), {
      children: null
    });

    if (!equal(newObservableQueryOptions, this.previousData.observableQueryOptions)) {
      this.previousData.observableQueryOptions = newObservableQueryOptions;
      this.currentObservable.query.setOptions(newObservableQueryOptions).catch(function () {});
    }
  };

  QueryData.prototype.startQuerySubscription = function () {
    var _this = this;

    if (this.currentObservable.subscription || this.getOptions().skip) return;
    var obsQuery = this.currentObservable.query;
    this.currentObservable.subscription = obsQuery.subscribe({
      next: function (_a) {
        var loading = _a.loading,
            networkStatus = _a.networkStatus,
            data = _a.data;
        var previousResult = _this.previousData.result;

        if (previousResult && previousResult.loading === loading && previousResult.networkStatus === networkStatus && equal(previousResult.data, data)) {
          return;
        }

        _this.onNewData();
      },
      error: function (error) {
        _this.resubscribeToQuery();

        if (!error.hasOwnProperty('graphQLErrors')) throw error;
        var previousResult = _this.previousData.result;

        if (previousResult && previousResult.loading || !equal(error, _this.previousData.error)) {
          _this.previousData.error = error;

          _this.onNewData();
        }
      }
    });
  };

  QueryData.prototype.resubscribeToQuery = function () {
    this.removeQuerySubscription();
    var lastError = this.currentObservable.query.getLastError();
    var lastResult = this.currentObservable.query.getLastResult();
    this.currentObservable.query.resetLastResults();
    this.startQuerySubscription();
    Object.assign(this.currentObservable.query, {
      lastError: lastError,
      lastResult: lastResult
    });
  };

  QueryData.prototype.getQueryResult = function () {
    var result = this.observableQueryFields();
    var options = this.getOptions();

    if (options.skip) {
      result = __assign(__assign({}, result), {
        data: undefined,
        error: undefined,
        loading: false,
        called: true
      });
    } else {
      var currentResult = this.currentObservable.query.getCurrentResult();
      var loading = currentResult.loading,
          partial = currentResult.partial,
          networkStatus = currentResult.networkStatus,
          errors = currentResult.errors;
      var error = currentResult.error,
          data = currentResult.data;

      if (errors && errors.length > 0) {
        error = new ApolloError({
          graphQLErrors: errors
        });
      }

      result = __assign(__assign({}, result), {
        loading: loading,
        networkStatus: networkStatus,
        error: error,
        called: true
      });

      if (loading) {
        var previousData = this.previousData.result && this.previousData.result.data;
        result.data = previousData && data ? __assign(__assign({}, previousData), data) : previousData || data;
      } else if (error) {
        Object.assign(result, {
          data: (this.currentObservable.query.getLastResult() || {}).data
        });
      } else {
        var fetchPolicy = this.currentObservable.query.options.fetchPolicy;
        var partialRefetch = options.partialRefetch;

        if (partialRefetch && !data && partial && fetchPolicy !== 'cache-only') {
          Object.assign(result, {
            loading: true,
            networkStatus: NetworkStatus.loading
          });
          result.refetch();
          return result;
        }

        result.data = data;
      }
    }

    result.client = this.client;
    this.previousData.loading = this.previousData.result && this.previousData.result.loading || false;
    this.previousData.result = result;
    this.currentObservable.query && this.currentObservable.query.resetQueryStoreErrors();
    return result;
  };

  QueryData.prototype.handleErrorOrCompleted = function () {
    var obsQuery = this.currentObservable.query;
    if (!obsQuery || !this.previousData.result) return;
    var _a = this.previousData.result,
        data = _a.data,
        loading = _a.loading,
        error = _a.error;

    if (!loading) {
      var _b = this.getOptions(),
          query = _b.query,
          variables = _b.variables,
          onCompleted = _b.onCompleted,
          onError = _b.onError;

      if (this.previousOptions && !this.previousData.loading && equal(this.previousOptions.query, query) && equal(this.previousOptions.variables, variables)) {
        return;
      }

      if (onCompleted && !error) {
        onCompleted(data);
      } else if (onError && error) {
        onError(error);
      }
    }
  };

  QueryData.prototype.removeQuerySubscription = function () {
    if (this.currentObservable.subscription) {
      this.currentObservable.subscription.unsubscribe();
      delete this.currentObservable.subscription;
    }
  };

  QueryData.prototype.observableQueryFields = function () {
    var observable = this.currentObservable.query;
    return {
      variables: observable.variables,
      refetch: this.obsRefetch,
      fetchMore: this.obsFetchMore,
      updateQuery: this.obsUpdateQuery,
      startPolling: this.obsStartPolling,
      stopPolling: this.obsStopPolling,
      subscribeToMore: this.obsSubscribeToMore
    };
  };

  return QueryData;
}(OperationData);

function useDeepMemo(memoFn, key) {
  var ref = react.useRef();

  if (!ref.current || !equal(key, ref.current.key)) {
    ref.current = {
      key: key,
      value: memoFn()
    };
  }

  return ref.current.value;
}

function useBaseQuery(query, options, lazy) {
  if (lazy === void 0) {
    lazy = false;
  }

  var context = react.useContext(getApolloContext());

  var _a = react.useReducer(function (x) {
    return x + 1;
  }, 0),
      tick = _a[0],
      forceUpdate = _a[1];

  var updatedOptions = options ? __assign(__assign({}, options), {
    query: query
  }) : {
    query: query
  };
  var queryDataRef = react.useRef();
  var queryData = queryDataRef.current || new QueryData({
    options: updatedOptions,
    context: context,
    onNewData: function () {
      if (!queryData.ssrInitiated()) {
        Promise.resolve().then(forceUpdate);
      } else {
        forceUpdate();
      }
    }
  });
  queryData.setOptions(updatedOptions);
  queryData.context = context;

  if (queryData.ssrInitiated() && !queryDataRef.current) {
    queryDataRef.current = queryData;
  }

  var memo = {
    options: __assign(__assign({}, updatedOptions), {
      onError: undefined,
      onCompleted: undefined
    }),
    context: context,
    tick: tick
  };
  var result = useDeepMemo(function () {
    return lazy ? queryData.executeLazy() : queryData.execute();
  }, memo);
  var queryResult = lazy ? result[1] : result;
  react.useEffect(function () {
    if (!queryDataRef.current) {
      queryDataRef.current = queryData;
    }

    return function () {
      return queryData.cleanup();
    };
  }, []);
  react.useEffect(function () {
    return queryData.afterExecute({
      lazy: lazy
    });
  }, [queryResult.loading, queryResult.networkStatus, queryResult.error, queryResult.data]);
  return result;
}

function useQuery(query, options) {
  return useBaseQuery(query, options, false);
}

function useLazyQuery(query, options) {
  return useBaseQuery(query, options, true);
}

var MutationData = function (_super) {
  __extends(MutationData, _super);

  function MutationData(_a) {
    var options = _a.options,
        context = _a.context,
        result = _a.result,
        setResult = _a.setResult;

    var _this = _super.call(this, options, context) || this;

    _this.runMutation = function (mutationFunctionOptions) {
      if (mutationFunctionOptions === void 0) {
        mutationFunctionOptions = {};
      }

      _this.onMutationStart();

      var mutationId = _this.generateNewMutationId();

      return _this.mutate(mutationFunctionOptions).then(function (response) {
        _this.onMutationCompleted(response, mutationId);

        return response;
      }).catch(function (error) {
        _this.onMutationError(error, mutationId);

        if (!_this.getOptions().onError) throw error;
      });
    };

    _this.verifyDocumentType(options.mutation, DocumentType.Mutation);

    _this.result = result;
    _this.setResult = setResult;
    _this.mostRecentMutationId = 0;
    return _this;
  }

  MutationData.prototype.execute = function (result) {
    this.isMounted = true;
    this.verifyDocumentType(this.getOptions().mutation, DocumentType.Mutation);
    result.client = this.refreshClient().client;
    return [this.runMutation, result];
  };

  MutationData.prototype.afterExecute = function () {
    this.isMounted = true;
    return this.unmount.bind(this);
  };

  MutationData.prototype.cleanup = function () {};

  MutationData.prototype.mutate = function (mutationFunctionOptions) {
    var _a = this.getOptions(),
        mutation = _a.mutation,
        variables = _a.variables,
        optimisticResponse = _a.optimisticResponse,
        update = _a.update,
        _b = _a.context,
        mutationContext = _b === void 0 ? {} : _b,
        _c = _a.awaitRefetchQueries,
        awaitRefetchQueries = _c === void 0 ? false : _c,
        fetchPolicy = _a.fetchPolicy;

    var mutateOptions = __assign({}, mutationFunctionOptions);

    var mutateVariables = Object.assign({}, variables, mutateOptions.variables);
    delete mutateOptions.variables;
    return this.refreshClient().client.mutate(__assign({
      mutation: mutation,
      optimisticResponse: optimisticResponse,
      refetchQueries: mutateOptions.refetchQueries || this.getOptions().refetchQueries,
      awaitRefetchQueries: awaitRefetchQueries,
      update: update,
      context: mutationContext,
      fetchPolicy: fetchPolicy,
      variables: mutateVariables
    }, mutateOptions));
  };

  MutationData.prototype.onMutationStart = function () {
    if (!this.result.loading && !this.getOptions().ignoreResults) {
      this.updateResult({
        loading: true,
        error: undefined,
        data: undefined,
        called: true
      });
    }
  };

  MutationData.prototype.onMutationCompleted = function (response, mutationId) {
    var _a = this.getOptions(),
        onCompleted = _a.onCompleted,
        ignoreResults = _a.ignoreResults;

    var data = response.data,
        errors = response.errors;
    var error = errors && errors.length > 0 ? new ApolloError({
      graphQLErrors: errors
    }) : undefined;

    var callOncomplete = function () {
      return onCompleted ? onCompleted(data) : null;
    };

    if (this.isMostRecentMutation(mutationId) && !ignoreResults) {
      this.updateResult({
        called: true,
        loading: false,
        data: data,
        error: error
      });
    }

    callOncomplete();
  };

  MutationData.prototype.onMutationError = function (error, mutationId) {
    var onError = this.getOptions().onError;

    if (this.isMostRecentMutation(mutationId)) {
      this.updateResult({
        loading: false,
        error: error,
        data: undefined,
        called: true
      });
    }

    if (onError) {
      onError(error);
    }
  };

  MutationData.prototype.generateNewMutationId = function () {
    return ++this.mostRecentMutationId;
  };

  MutationData.prototype.isMostRecentMutation = function (mutationId) {
    return this.mostRecentMutationId === mutationId;
  };

  MutationData.prototype.updateResult = function (result) {
    if (this.isMounted && (!this.previousResult || !equal(this.previousResult, result))) {
      this.setResult(result);
      this.previousResult = result;
    }
  };

  return MutationData;
}(OperationData);

function useMutation(mutation, options) {
  var context = react.useContext(getApolloContext());

  var _a = react.useState({
    called: false,
    loading: false
  }),
      result = _a[0],
      setResult = _a[1];

  var updatedOptions = options ? __assign(__assign({}, options), {
    mutation: mutation
  }) : {
    mutation: mutation
  };
  var mutationDataRef = react.useRef();

  function getMutationDataRef() {
    if (!mutationDataRef.current) {
      mutationDataRef.current = new MutationData({
        options: updatedOptions,
        context: context,
        result: result,
        setResult: setResult
      });
    }

    return mutationDataRef.current;
  }

  var mutationData = getMutationDataRef();
  mutationData.setOptions(updatedOptions);
  mutationData.context = context;
  react.useEffect(function () {
    return mutationData.afterExecute();
  });
  return mutationData.execute(result);
}

var SubscriptionData = function (_super) {
  __extends(SubscriptionData, _super);

  function SubscriptionData(_a) {
    var options = _a.options,
        context = _a.context,
        setResult = _a.setResult;

    var _this = _super.call(this, options, context) || this;

    _this.currentObservable = {};
    _this.setResult = setResult;

    _this.initialize(options);

    return _this;
  }

  SubscriptionData.prototype.execute = function (result) {
    if (this.getOptions().skip === true) {
      this.cleanup();
      return {
        loading: false,
        error: undefined,
        data: undefined,
        variables: this.getOptions().variables
      };
    }

    var currentResult = result;

    if (this.refreshClient().isNew) {
      currentResult = this.getLoadingResult();
    }

    var shouldResubscribe = this.getOptions().shouldResubscribe;

    if (typeof shouldResubscribe === 'function') {
      shouldResubscribe = !!shouldResubscribe(this.getOptions());
    }

    if (shouldResubscribe !== false && this.previousOptions && Object.keys(this.previousOptions).length > 0 && (this.previousOptions.subscription !== this.getOptions().subscription || !equal(this.previousOptions.variables, this.getOptions().variables) || this.previousOptions.skip !== this.getOptions().skip)) {
      this.cleanup();
      currentResult = this.getLoadingResult();
    }

    this.initialize(this.getOptions());
    this.startSubscription();
    this.previousOptions = this.getOptions();
    return __assign(__assign({}, currentResult), {
      variables: this.getOptions().variables
    });
  };

  SubscriptionData.prototype.afterExecute = function () {
    this.isMounted = true;
  };

  SubscriptionData.prototype.cleanup = function () {
    this.endSubscription();
    delete this.currentObservable.query;
  };

  SubscriptionData.prototype.initialize = function (options) {
    if (this.currentObservable.query || this.getOptions().skip === true) return;
    this.currentObservable.query = this.refreshClient().client.subscribe({
      query: options.subscription,
      variables: options.variables,
      fetchPolicy: options.fetchPolicy
    });
  };

  SubscriptionData.prototype.startSubscription = function () {
    if (this.currentObservable.subscription) return;
    this.currentObservable.subscription = this.currentObservable.query.subscribe({
      next: this.updateCurrentData.bind(this),
      error: this.updateError.bind(this),
      complete: this.completeSubscription.bind(this)
    });
  };

  SubscriptionData.prototype.getLoadingResult = function () {
    return {
      loading: true,
      error: undefined,
      data: undefined
    };
  };

  SubscriptionData.prototype.updateResult = function (result) {
    if (this.isMounted) {
      this.setResult(result);
    }
  };

  SubscriptionData.prototype.updateCurrentData = function (result) {
    var onSubscriptionData = this.getOptions().onSubscriptionData;
    this.updateResult({
      data: result.data,
      loading: false,
      error: undefined
    });

    if (onSubscriptionData) {
      onSubscriptionData({
        client: this.refreshClient().client,
        subscriptionData: result
      });
    }
  };

  SubscriptionData.prototype.updateError = function (error) {
    this.updateResult({
      error: error,
      loading: false
    });
  };

  SubscriptionData.prototype.completeSubscription = function () {
    var onSubscriptionComplete = this.getOptions().onSubscriptionComplete;
    if (onSubscriptionComplete) onSubscriptionComplete();
    this.endSubscription();
  };

  SubscriptionData.prototype.endSubscription = function () {
    if (this.currentObservable.subscription) {
      this.currentObservable.subscription.unsubscribe();
      delete this.currentObservable.subscription;
    }
  };

  return SubscriptionData;
}(OperationData);

function useSubscription(subscription, options) {
  var context = react.useContext(getApolloContext());
  var updatedOptions = options ? __assign(__assign({}, options), {
    subscription: subscription
  }) : {
    subscription: subscription
  };

  var _a = react.useState({
    loading: !updatedOptions.skip,
    error: undefined,
    data: undefined
  }),
      result = _a[0],
      setResult = _a[1];

  var subscriptionDataRef = react.useRef();

  function getSubscriptionDataRef() {
    if (!subscriptionDataRef.current) {
      subscriptionDataRef.current = new SubscriptionData({
        options: updatedOptions,
        context: context,
        setResult: setResult
      });
    }

    return subscriptionDataRef.current;
  }

  var subscriptionData = getSubscriptionDataRef();
  subscriptionData.setOptions(updatedOptions, true);
  subscriptionData.context = context;
  react.useEffect(function () {
    return subscriptionData.afterExecute();
  });
  react.useEffect(function () {
    return subscriptionData.cleanup.bind(subscriptionData);
  }, []);
  return subscriptionData.execute(result);
}

function useApolloClient() {
  var client = react.useContext(getApolloContext()).client;
  invariant(client, 1) ;
  return client;
}

export { ApolloProvider as A, DocumentType as D, useMutation as a, useSubscription as b, useLazyQuery as c, useApolloClient as d, parser as p, useQuery as u };
